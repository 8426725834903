import { hydrateRoot } from "react-dom/client";
import { HydratedRouter } from "react-router/dom";
import { startTransition, StrictMode } from "react";
import posthog from "posthog-js";

if (ENV.FLY_CONTEXT === "production" && ENV.SENTRY_DSN) {
	void import("~/utils/monitoring.client").then(({ init }) => init());
}

function hydrate() {
	posthog.init(ENV.POSTHOG_PUBLIC_KEY, {
		api_host: "https://aiven.io/ph",
		ui_host: "https://eu.posthog.com",
		autocapture: false,
		capture_pageview: false,
		capture_pageleave: false,
		disable_session_recording: true,
		opt_out_capturing_by_default: true,
	});
	startTransition(() => {
		hydrateRoot(
			document,
			<StrictMode>
				<HydratedRouter />
			</StrictMode>
		);
	});
}

if (window.requestIdleCallback) {
	window.requestIdleCallback(hydrate);
} else {
	window.setTimeout(hydrate, 1);
}
